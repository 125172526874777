.skills-component {
  .title {
    font-size: 50px;
  }
  .skills-info {
    max-width: 1200px;
    display: flex;
    margin: 1px auto;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
  }
  .skills-wrapper {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    justify-content: center;
    background-color: black;
    border: 1px solid white;
    border-radius: 32px;
    margin-top: 1rem;

    div {
      display: block;
      margin: 25px 30px 20px;
      font-size: inherit;
      text-align: center;

      img {
        width: 70px;
        height: 70px;
        z-index: 999;
        position: relative;
      }
      p {
        color: white !important;
        padding-top: 1rem;
      }
    }
  }

  @media (max-width: 991px) {
    .skills-wrapper {
      display: none;
    }
  }
}
