.containers {
  position: relative;
  width: 320px;
  margin: 100px auto 0 auto;
  perspective: 1000px;

  .carousel {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    animation: rotate360 60s infinite forwards linear;

    .carousel__face {
      position: absolute;
      width: 300px;
      height: 187px;
      top: 20px;
      left: 10px;
      right: 10px;
      background-size: cover;
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.1);
      display: flex;
      background-position: center center;

      span {
        margin: auto;
        font-size: 2rem;
      }

      &:nth-child(1) {
        background-image: url("../../assets/images/screencapture-syri-net.png");
        transform: rotateY(0deg) translateZ(430px);
      }
      &:nth-child(2) {
        background-image: url("../../assets/images/screencapture-linkedin-feed.png");
        transform: rotateY(40deg) translateZ(430px);
      }
      &:nth-child(3) {
        background-image: url("../../assets/images/screencapture-cloudskillsboost.png");
        transform: rotateY(80deg) translateZ(430px);
      }
      &:nth-child(4) {
        background-image: url("../../assets/images/screencapture-fjala-al.png");

        transform: rotateY(120deg) translateZ(430px);
      }
      &:nth-child(5) {
        background-image: url("../../assets/images/image-10.jpg");
        transform: rotateY(160deg) translateZ(430px);
      }
      &:nth-child(6) {
        background-image: url("../../assets/images/htb-reward.png");
        transform: rotateY(200deg) translateZ(430px);
      }
      &:nth-child(7) {
        background-image: url("../../assets/images/screencapture-linkedin-feed-update.png");
        transform: rotateY(240deg) translateZ(430px);
      }
      &:nth-child(8) {
        background-image: url("../../assets/images/screencapture-cloudskillsboost-google-public-profiles.png");
        transform: rotateY(280deg) translateZ(430px);
      }
      &:nth-child(9) {
        background-image: url("../../assets/images/image-11.png");
        transform: rotateY(320deg) translateZ(430px);
      }
    }
  }

  @keyframes rotate360 {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(-360deg);
    }
  }
}
