.about-container {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.about-container.fade-in {
  opacity: 1;
  transform: translateX(0);
}

.about-container .row {
  align-items: center;
}

.about-left {
  display: block;
  position: relative;
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.about-left.fade-in {
  opacity: 1;
  transform: translateX(0);
}

.ipad {
  background: black;
  width: 420px;
  height: 580px;
  margin: auto;
  position: relative;
  border-radius: 15px;
  border: solid #fcfcfc;
  border-width: 32px 12px;
  box-shadow: -4px -4px 4px 0px #c9ba93, 4px 4px 4px 0px #c9ba93;
}

.ipad:after,
.ipad:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background: #3f3f3f;
  border-radius: 100%;
  top: -20px;
  left: 0;
  right: 0;
  margin: auto;
}

.ipad:before {
  content: "\2610";
  color: #cfcfcf;
  line-height: 20px;
  text-align: center;
  font-size: 10px;
  background: #fcfcfc;
  border: 0.5px solid #cfcfcf;
  width: 20px;
  height: 20px;
  top: auto;
  bottom: -25px;
}

.a-right {
  display: block;
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.a-right {
  padding: 0px !important;
}

.a-right.fade-in {
  opacity: 1;
  transform: translateX(0);
}

.a-title {
  font-size: 50px;
}

.a-desc {
  line-height: 40px;
  z-index: 1;
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 60px;
  max-width: 616px;
  text-align: justify;
}

@media (max-width: 440px) {
  .ipad {
    width: 70%;
    max-height: 340px;
  }
  .about-left {
    padding-top: 4rem;
  }
}

/* Mobile (portrait/landscape), iPad */
@media (max-width: 991px) {
  .about-container {
    padding-bottom: 3rem;
    padding-top: 7rem;
  }

  .a-desc {
    line-height: 40px;
    max-width: 90%;
    text-align: center;
    margin: auto;
  }

  .a-title {
    font-size: 34px;
  }

  .a-right {
    text-align: center;
  }
  .ipad {
    transform: rotate(90deg);
  }
}

/* iPad Pro, devicees form 992 to 1200 */
@media (max-width: 1200px) {
  .a-desc {
    font-size: 14px;
  }
}

@media (max-width: 1223px) {
}

/* Devices From 1201 to 1440*/
@media (max-width: 1440px) {
}
