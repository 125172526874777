.slider-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.title {
  text-align: center;
  margin-bottom: 2rem;
}

.section-center {
  overflow: hidden;
  height: 100%;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.section-center button {
  background: transparent;
  padding: 0px;
  border-radius: 20px;
  border-color: transparent;
}

.moments-img {
  max-width: 100%;
  height: auto;
  display: block;
  object-fit: contain;
  position: relative;
}

.title {
  text-transform: capitalize;
  margin-bottom: 0.75rem;
}
.text {
  max-width: 35em;
  margin: 0 auto;
  margin-top: 2rem;
  line-height: 2;
}
.icon {
  font-size: 3rem;
  margin-top: 1rem;
}
.prev,
.next {
  color: #fff;
  position: absolute;
  top: 17rem;
  transform: translateY(-50%);
  display: grid;
  place-items: center;
  background-color: #59b256;
  border-color: #59b256;
  cursor: pointer !important;
  transition: all 0.3s linear;
  opacity: 0.2;
}
.prev {
  left: 0;
}
.next {
  right: 0;
}

.text {
  max-width: 45em;
}
.prev,
.next {
  font-size: 1.5rem;
}

article {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
article.lastSlide {
  transform: translateX(0%);
}
article.nextSlide {
  transform: translateX(0%);
}

@media (max-width: 570px) {
  .slider-section {
    background-size: cover !important;
  }
  .about-left {
    padding-bottom: 2rem;
  }
}

@media (max-width: 440px) {
}

/* Mobile (portrait/landscape), iPad */
@media (max-width: 991px) {
  .section-center {
    transform: rotate(-90deg);
  }
  .moments-img {
    max-width: 70%;
  }
}

/* iPad Pro, devicees form 992 to 1200 */
@media (max-width: 1200px) {
  .slider-section {
    display: block;
    position: relative;
  }
}

@media (max-width: 1223px) {
}

/* Devices From 1201 to 1440*/
@media (max-width: 1440px) {
}
