.moments-container {
}
.pl {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.pl-texts {
}

.pl-title {
  font-size: 50px;
  padding-bottom: 1rem;
}

.pl-desc {
  margin: 20px 0px;
  line-height: 40px;
}

.pl-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Mobile (portrait/landscape), iPad */
@media (max-width: 991px) {
  .moments-container {
    padding-bottom: 4rem;
  }
  .pl-texts {
    width: 100%;
  }
  .pl-desc {
    line-height: 24px;
  }
  .pl {
    padding: 0;
  }
  .pl-title {
    padding-top: 2rem;
  }
}

/* iPad Pro, devicees form 992 to 1200 */
@media (max-width: 1200px) {
}

@media (max-width: 1223px) {
}

/* Devices From 1201 to 1440*/
@media (max-width: 1440px) {
}
