.c {
  position: relative;

  .c-wrapper {
    // padding: 60px;
    display: flex;

    flex-direction: column;
  }

  .marquee-container {
    font-size: 7rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-weight: 700;
    background-color: black;

    .gradient-text {
      background: linear-gradient(to right, crimson, pink, springgreen);
      background-size: 200% 200%;
      animation: rainbow 2s ease-in-out infinite;
      background-clip: text;
      -webkit-background-clip: text;
      font-size: 5rem;
      display: block;
      text-align: center;
      transition: color 0.2s ease-in-out;
      text-transform: lowercase;
      font-weight: 300;
      color: white;

      &:hover {
        color: rgba(0, 0, 0, 0);
      }

      @keyframes rainbow {
        0% {
          background-position: left;
        }
        50% {
          background-position: right;
        }
        100% {
          background-position: left;
        }
      }
    }
  }

  .socials-contact {
    text-align: center;
    align-content: center;
    display: block;
    left: 0;
    right: 0;
    margin: 1px auto;

    img {
      max-width: 40%;
      height: auto;
    }
    ul {
      position: relative;
      right: 0;
      left: 0;
      display: flex;
      margin: 0;
      padding: 0;
    }

    ul li {
      list-style: none;
      margin: 0 5px;

      a {
        text-decoration: none;
        display: block;
        width: 210px;
        height: 80px;
        background: #fff;
        text-align: left;
        padding-left: 20px;
        transform: rotate(-30deg) skew(25deg) translate(0, 0);
        transition: 0.5s;
        box-shadow: -20px 20px 10px rgba(0, 0, 0, 0.5);

        &::before {
          content: "";
          position: absolute;
          top: 10px;
          left: -20px;
          height: 100%;
          width: 20px;
          background: #b1b1b1;
          transform: 0.5s;
          transform: rotate(0deg) skewY(-45deg);
        }

        &::after {
          content: "";
          position: absolute;
          bottom: -20px;
          left: -10px;
          height: 20px;
          width: 100%;
          background: #b1b1b1;
          transform: 0.5s;
          transform: rotate(0deg) skewX(-45deg);
        }

        &:hover {
          transform: rotate(-30deg) skew(25deg) translate(20px, -15px);
          box-shadow: -50px 50px 50px rgba(0, 0, 0, 0.5);
        }

        img {
          font-size: 40px;
          color: #262626;
          line-height: 80px;
          transition: 0.5s;
          top: 2px;
          position: relative;
          left: -10px;
        }
        span {
          padding: 0;
          margin: 0;
          color: #262626;
          letter-spacing: 4px;
          transition: 0.5s;

          &:hover {
            color: white;
          }
        }
      }
    }

    ul li:hover:nth-child(1) a {
      background: #3b5998;
    }
    ul li:hover:nth-child(1) a:before {
      background: #365492;
    }
    ul li:hover:nth-child(1) a:after {
      background: #4a69ad;
    }

    ul li:hover:nth-child(2) a {
      background: #dd4b39;
    }
    ul li:hover:nth-child(2) a:before {
      background: #b33a2b;
    }
    ul li:hover:nth-child(2) a:after {
      background: #e66a5a;
    }
  }
}

@media (max-width: 620px) {
  .socials-contact img {
    max-width: 20%;
  }
  input {
    width: 100%;
  }
}

/* Mobile (portrait/landscape), iPad */
@media (max-width: 991px) {
  .c-wrapper {
    padding: 10px;
  }
}

/* iPad Pro, devicees form 992 to 1200 */
@media (max-width: 1200px) {
}

@media (max-width: 1223px) {
}

/* Devices From 1201 to 1440*/
@media (max-width: 1440px) {
}
