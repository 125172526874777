.footer-container {
  display: block;
  position: relative;
  padding-top: 6rem;
}

.copyrights p {
  display: block;
  position: relative;
  text-align: center;
  color: #738276;
}

/* Mobile (portrait/landscape), iPad */
@media (max-width: 991px) {
}

/* iPad Pro, devicees form 992 to 1200 */
@media (max-width: 1200px) {
}

@media (max-width: 1223px) {
}

/* Devices From 1201 to 1440*/
@media (max-width: 1440px) {
}
