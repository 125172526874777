::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: black;
}

::-webkit-scrollbar {
  width: 3px;
  background-color: black;
}

::-webkit-scrollbar-thumb {
  border-radius: 15px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.intro-container {
  overflow-x: hidden;
}

.intro-container .row {
  align-items: center;
}

.i-left {
  display: block;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 2s ease-in-out, transform 1s ease-in-out;
}

.i-right {
  display: block;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 2s ease-in-out, transform 1s ease-in-out;
}

.intro-container.fade-in .i-left {
  opacity: 1;
  transform: translateX(0);
}

.intro-container.fade-in .i-right {
  opacity: 1;
  transform: translateX(0);
}

.i-left-wrapper {
  display: block;
  justify-content: space-between;
  position: relative;
  left: 1rem;
}

.i-intro {
  font-weight: 300;
  font-size: 50px;
}

.i-intro span {
  display: inline-block;
  position: relative;
}

.i-name {
  font-size: 72px;
}

.i-name span {
  font-size: 60px;
  display: block;
}

.i-title {
  height: 50px;
  overflow: hidden;
  padding-left: 10px;
}

.i-title-wrapper {
  height: 100%;
  animation: move 15s ease-in-out infinite alternate;
}

@keyframes move {
  25% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(-100px);
  }
  75% {
    transform: translateY(-150px);
  }
  100% {
    transform: translateY(-200px);
  }
}

.i-title-item {
  height: 50px;
  font-size: 30px;
  font-weight: 700;
  color: #59b256;
  display: block;
  align-items: center;
  text-transform: uppercase;
  font-style: italic;
}

.i-scroll {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 20px;
}

.i-img {
  object-fit: cover;
  position: relative;
}

.i-bg {
  clip-path: polygon(100% 0%, 100% 51%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
  background-image: linear-gradient(to top, #fff 0%, #453a94 80%);
  width: 120%;
  height: 54%;
  left: 1rem;
  position: absolute;
  right: 0;
  opacity: 0.5;
  top: 10rem;
}

@media (max-width: 550px) {
  .i-title-item {
    font-size: 17px;
  }
  .i-intro {
    font-size: 20px !important;
  }
}

/* Mobile (portrait/landscape), iPad */
@media (max-width: 991px) {
  .i-left-wrapper {
    padding: 0px !important;
    top: 0 !important;
    left: 0;
  }
  .i {
    display: block;
    width: 100% !important;
    text-align: center;
    height: unset;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .i-intro {
    padding-left: 0px;
  }
  .i-title-item {
    display: block;
  }

  .i-bg {
    clip-path: polygon(
      0% 15%,
      15% 15%,
      15% 0%,
      85% 0%,
      85% 15%,
      100% 15%,
      100% 85%,
      85% 85%,
      85% 100%,
      15% 100%,
      15% 85%,
      0% 85%
    );
    background-color: #59b256;
    width: 100%;
    height: 10% !important;
    left: 0rem;
    top: 15rem;
  }
  .i-img {
    top: 0px;
    position: relative;
  }
  .i-second {
    display: block;
    position: relative;
    text-align: center;
    width: 100% !important;
  }
  .i-right {
    padding-top: 0px;
    transform: translateX(0) !important;
  }

  .intro-container {
    padding-top: 3rem;
    padding-bottom: 0;
  }
}

/* iPad Pro, devices from 992 to 1200 */
@media (max-width: 1200px) {
}

@media (max-width: 1224px) {
  .i-name {
    font-size: 40px !important;
  }
  .i-name span {
    font-size: 30px !important;
  }
  .i-bg {
    height: 59%;
  }
  .i-intro {
    font-size: 30px;
  }
}

/* Devices from 1201 to 1440 */
@media (max-width: 1440px) {
  .i-name {
    font-size: 60px;
  }

  .i-name span {
    font-size: 40px;
  }
  .i-intro {
    font-size: 40px;
  }
}

@media (min-width: 1441px) {
}

@media (min-width: 140px) {
  .container {
    min-width: 100% !important;
  }
}
